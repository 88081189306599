<template>
  <mi-list-item class="q-py-md">
    <mi-list-item-section class="text-weight-light">
      <!-- Title -->
      {{ item.explanation }}

      <!-- Planning period range -->
      <mi-list-item-label class="q-mt-md" caption>
        {{ item.rangeFrom }} - {{ item.rangeTo }}
      </mi-list-item-label>
    </mi-list-item-section>
  </mi-list-item>
</template>

<script>
  export default {
    name: 'BomValidationResultsElementsIncomplete',
    props: {
      item: {
        type: Object,
        required: true
      }
    }
  }
</script>
