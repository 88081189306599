<template>
  <div class="bom-results row relative-position full-height no-wrap q-px-lg q-pb-md">
    <div class="col column no-wrap full-height">
      <div class="row justify-between items-center">
        <!-- Validation results tabs -->
        <div class="col-auto">
          <error-types-tabs
            v-model="activeBomValidationErrorType"
            :errors-total-sizes="validationResultsTotalSize[activeBomValidationResultType.key] || {}"
          ></error-types-tabs>
        </div>

        <!-- Validation error type select -->
        <div class="col-auto">
          <result-types-select
            v-model="activeBomValidationResultType"
            :results-total-sizes="validationResultsTotalSize"
            @value:has-changed="saveCurrentElements"
          ></result-types-select>
        </div>
      </div>

      <bom-validation-results-elements-info
        v-show="showInfo.incomplete && activeBomValidationErrorType === BOM_VALIDATION_ERROR_TYPES.INCOMPLETE.key"
        :info-data="BOM_VALIDATION_ERROR_TYPES_MESSAGE.INCOMPLETE"
      >
      </bom-validation-results-elements-info>

      <!-- Validation results by error type -->
      <mi-tab-panels v-model="activeBomValidationErrorType" class="col">
        <!-- Unused KVs -->
        <result-elements :items="currentDisplayedElements" :name="BOM_VALIDATION_ERROR_TYPES.UNUSED.key">
          <template #default="{ item }">
            <unused-element
              :key="item.id"
              :item="item"
              :encoded-business-name="bomValidationResults.encodedBusinessName"
              :component-validation-id="bomValidationResults.id"
            ></unused-element>
          </template>
        </result-elements>

        <!-- Incomplete elements -->
        <result-elements :items="currentDisplayedElements" :name="BOM_VALIDATION_ERROR_TYPES.INCOMPLETE.key">
          <template #default="{ item }">
            <incomplete-element :key="item.id" :item="item"></incomplete-element>
          </template>
        </result-elements>

        <!-- Ambiguous elements -->
        <result-elements :items="currentDisplayedElements" :name="BOM_VALIDATION_ERROR_TYPES.AMBIGUOUS.key">
          <template #default="{ item }">
            <ambiguous-element :key="item.id" :item="item"></ambiguous-element>
          </template>
        </result-elements>
      </mi-tab-panels>
    </div>

    <!-- BOM validation details -->
    <div class="col-auto q-pl-lg">
      <validation-details :bom-validation-details="bomValidationResults"></validation-details>
    </div>

    <!-- Spinner -->
    <mi-inner-loading class="bg-white" :showing="isBomValidationReceiving">
      <mi-spinner size="70px"></mi-spinner>
      <h6 class="q-mt-lg q-mb-none"> Loading BOM validation. Please wait ... </h6>
    </mi-inner-loading>
  </div>
</template>

<script>
  import { getBOMValidation } from '@/api'

  import {
    BOM_VALIDATION_ERROR_TYPES,
    BOM_VALIDATION_ERROR_TYPES_MESSAGE,
    BOM_VALIDATION_RESULT_TYPES
  } from '@/constants'

  import { SET_EXPORT_DATA_VISIBILITY } from '@/store/modules/validations/mutationTypes'
  import { createNamespacedHelpers } from 'vuex'
  import BomValidationResultsElementsInfo from
    '@/components/validations/bom/validation-results/elements/BomValidationResultsElementsInfo.vue'
  import ErrorTypesTabs from './BomValidationResultsErrorTypesTabs.vue'
  import ResultTypesSelect from './BomValidationResultsTypesSelect.vue'
  import ValidationDetails from './BomValidationResultsDetails.vue'
  import ResultElements from './elements/BomValidationResultsElements.vue'
  import AmbiguousElement from './elements/BomValidationResultsElementsAmbiguous.vue'
  import IncompleteElement from './elements/BomValidationResultsElementsIncomplete.vue'
  import UnusedElement from './elements/BomValidationResultsElementsUnused.vue'

  const { mapMutations } = createNamespacedHelpers('validations')

  export default {
    name: 'BomValidationResults',
    components: {
      BomValidationResultsElementsInfo,
      AmbiguousElement,
      ErrorTypesTabs,
      IncompleteElement,
      ResultElements,
      ResultTypesSelect,
      UnusedElement,
      ValidationDetails
    },
    data: () => ({
      BOM_VALIDATION_ERROR_TYPES,
      BOM_VALIDATION_RESULT_TYPES,
      activeBomValidationErrorType: BOM_VALIDATION_ERROR_TYPES.AMBIGUOUS.key,
      activeBomValidationResultType: BOM_VALIDATION_RESULT_TYPES.COMPONENT_VARIANT,
      isBomValidationReceiving: false,
      bomValidationResults: {},
      showInfo: { incomplete: false }
    }),
    computed: {
      BOM_VALIDATION_ERROR_TYPES_MESSAGE() {
        return BOM_VALIDATION_ERROR_TYPES_MESSAGE
      },
      currentDisplayedElements() {
        const currentActiveResultType = this.bomValidationResults[this.activeBomValidationResultType?.key] || {}

        return currentActiveResultType[this.activeBomValidationErrorType] || []
      },
      validationResultsTotalSize() {
        const elementsSizes = {}

        Object.values(BOM_VALIDATION_RESULT_TYPES).forEach(({ key: validationResultType } = {}) => {
          const currentValidationResultType = this.bomValidationResults[validationResultType] || {}

          elementsSizes[validationResultType] = { totalSize: 0 }

          Object.values(BOM_VALIDATION_ERROR_TYPES).forEach(({ key: validationErrorType } = {}) => {
            const errorTypeTotalSize = currentValidationResultType[validationErrorType]?.length || 0

            elementsSizes[validationResultType][validationErrorType] = errorTypeTotalSize
            elementsSizes[validationResultType].totalSize += errorTypeTotalSize
          })
        })

        return elementsSizes
      }
    },
    async created() {
      const { id: bomValidationId } = this.$route.params || {}

      if (bomValidationId) {
        await this.getBomValidationResults(bomValidationId)
      }
    },
    methods: {
      ...mapMutations({ SET_EXPORT_DATA_VISIBILITY }),

      async getBomValidationResults(validationId = '') {
        this.isBomValidationReceiving = true

        try {
          this.bomValidationResults = await getBOMValidation(validationId)

          this.saveCurrentElements()
        }
        finally {
          this.isBomValidationReceiving = false
          this.containsAllPossibleCombinations()
        }
      },
      saveCurrentElements() {
        const activeBomValidationTypeData = this.bomValidationResults[this.activeBomValidationResultType.key]
        if (!activeBomValidationTypeData) return
        const {
          ambiguousElements,
          incompleteElements,
          unusedElements
        } = activeBomValidationTypeData

        const displayExportData = !!(ambiguousElements.length || incompleteElements.length || unusedElements.length)

        this.SET_EXPORT_DATA_VISIBILITY(displayExportData)
        this.containsAllPossibleCombinations()
      },
      containsAllPossibleCombinations() {
        const { containsAllIncompleteCombinations } = this.bomValidationResults
        this.showInfo.incomplete = containsAllIncompleteCombinations === false
          && this.bomValidationResults[this.activeBomValidationResultType.key].incompleteElements.length > 0
      }
    }
  }
</script>
