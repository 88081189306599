<template>
  <mi-card class="bom-validation-details column q-pa-xs">
    <!-- Title -->
    <mi-card-section>
      <h6 class="q-my-none"> BOM ID: {{ $route.params.id }} </h6>
    </mi-card-section>

    <!-- Validation information -->
    <mi-card-section v-if="validDetails">
      <p class="q-mb-sm">
        <strong
          v-if="bomValidationDetails.productModelType === 'PRODUCT_MODEL'"
          class="text-family-condensed"
        > Product Model:
        </strong>
        {{ bomValidationDetails.businessName }}
        <strong
          v-if="bomValidationDetails.productModelType === 'USER_EXPORTS'"
          class="text-family-condensed"
        > Product Model:
        </strong>
        {{ bomValidationDetails.userExportsName }}
      </p>
      <p class="q-mb-sm">
        <strong class="text-family-condensed"> Creation Date: </strong>
        {{ creationDateformatted }}
      </p>
      <p class="q-mb-sm">
        <strong class="text-family-condensed"> Component ID: </strong>
        {{ bomValidationDetails.componentId }}
      </p>
      <p class="q-mb-none">
        <strong class="text-family-condensed"> Planning Period: </strong>
        {{ bomValidationDetails.planningPeriodRange.from }} - {{ bomValidationDetails.planningPeriodRange.to }}
      </p>
    </mi-card-section>

    <!-- No details found caption -->
    <mi-card-section v-else class="column col-grow flex-center">
      <mi-icon name="warning-circle" size="1.75rem"></mi-icon>
      <h6 class="q-mb-none q-mt-sm"> No validation details found </h6>
    </mi-card-section>
  </mi-card>
</template>

<script>
  import formatDate from '@/utils/formatDate'

  export default {
    name: 'BomValidationResultsDetails',
    props: {
      bomValidationDetails: {
        type: Object,
        required: true
      }
    },
    computed: {
      validDetails() {
        return Object.keys(this.bomValidationDetails).length > 0
          && this.bomValidationDetails.businessName != null
          && this.bomValidationDetails.componentId != null
          && this.bomValidationDetails.planningPeriodRange != null
          && this.bomValidationDetails.startDate
      },
      creationDateformatted() {
        return formatDate(this.bomValidationDetails.startDate)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .bom-validation-details {
    min-width: 380px;
    min-height: 170px;
    background-color: $mi-silver-50;
  }
</style>
