<template>
  <mi-tabs
    :model-value="modelValue"
    class="bom-error-types-tabs"
    dense
    @update:model-value="updateActiveValidationErrorType"
  >
    <mi-tab
      v-for="({ key: validationErrorType, label }) in BOM_VALIDATION_ERROR_TYPES"
      :key="validationErrorType"
      :badge="errorsTotalSizes[validationErrorType]"
      :name="validationErrorType"
      :label="label"
    ></mi-tab>
  </mi-tabs>
</template>

<script>
  import { BOM_VALIDATION_ERROR_TYPES } from '@/constants'

  export default {
    name: 'BomValidationResultsErrorTypesTabs',
    props: {
      errorsTotalSizes: {
        type: Object,
        required: true
      },
      modelValue: {
        type: String,
        required: true
      }
    },
    emits: ['update:model-value'],
    data: () => ({ BOM_VALIDATION_ERROR_TYPES }),
    mounted() {
      const { selectedErrorType } = this.$route.query || {}
      const isRouteSelectedErrorTypeValid = Object.values(BOM_VALIDATION_ERROR_TYPES).some(
        errorType => errorType.key === selectedErrorType
      )

      if (isRouteSelectedErrorTypeValid) {
        this.emitUpdateModelValue(selectedErrorType)
      }
    },
    methods: {
      emitUpdateModelValue(modelValue = '') {
        this.$emit('update:model-value', modelValue)
      },
      updateActiveValidationErrorType(selectedErrorType = '') {
        this.emitUpdateModelValue(selectedErrorType)
        this.$router.replace({
          query: { ...this.$route.query, selectedErrorType }
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .bom-error-types-tabs ::v-deep(.mi-tab) {
    padding: 0 1rem;
  }

  .bom-error-types-tabs ::v-deep(.q-tab__label) {
    font-size: .875rem;
  }
</style>
