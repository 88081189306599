<template>
  <mi-expansion-item
    v-model="isExplanationExpanded"
    class="bom-unused-element"
    group="unusedKVs"
    expand-icon-toggle
    no-expand-icon
    outlined
    popup
  >
    <template #header>
      <!-- Title -->
      <mi-list-item-section class="text-weight-medium">
        <mi-list-item-label lines="2">
          {{ item.id2 }} - {{ item.name }}
          | From {{ item.rangeFrom }} To {{ item.rangeTo }}
        </mi-list-item-label>
      </mi-list-item-section>

      <div class="flex items-center q-ml-md">
        <!-- Why explanation button -->
        <mi-btn
          v-show="!isExplanationExpanded"
          :loading="isExplanationLoading"
          color="accent"
          dense
          @click="getUnusedElementExplanation()"
        >
          Why
        </mi-btn>

        <!-- Close explanation button -->
        <mi-btn
          v-show="isExplanationExpanded"
          class="text-caption"
          icon="close"
          icon-size=".875rem"
          fab
          icon-type
          flat
          @click="isExplanationExpanded = false"
        ></mi-btn>
      </div>
    </template>

    <!-- Explanation -->
    <validation-explanation
      v-if="isExplanationExpanded"
      class="q-virtual-scroll--with-prev"
      :explanation="explanation"
    ></validation-explanation>
  </mi-expansion-item>
</template>

<script>
  import {
    requestBOMValidationExplanation,
    getBOMValidationExplanation
  } from '@/api'
  import recordAnalytics from '@/utils/analytics/recordAnalytics'
  import ValidationExplanation from '@/components/validations/ValidationExplanation.vue'
  import notify from '@/utils/notify'
  import { BOM_WHY_EXPLANATION } from '@/utils/analytics/constants'
  import { poll } from '@/api/polling'

  export default {
    name: 'BomValidationResultsElementsUnused',
    components: { ValidationExplanation },
    props: {
      item: {
        type: Object,
        required: true
      },
      encodedBusinessName: {
        type: String,
        required: true
      },
      componentValidationId: {
        type: String,
        required: true
      }
    },
    data: () => ({
      isExplanationExpanded: false,
      isExplanationLoading: false,
      explanation: {
        conflicts: [],
        relevantChoices: []
      },
      clearPoll: null
    }),
    beforeUnmount() {
      this.clearPoll?.()
    },
    methods: {
      async getUnusedElementExplanation() {
        try {
          const {
            componentOid: componentID,
            oid: componentVariantID
          } = this.item

          this.isExplanationLoading = true

          const { executePoll, clearPoll } = poll()
          this.clearPoll = clearPoll

          const { whyExplanationResponse } = await executePoll({
            correlationIdEndpoint: {
              getCorrelationId: requestBOMValidationExplanation,
              params:
                {
                  componentValidationID: this.componentValidationId,
                  componentID,
                  componentVariantID
                }
            },
            asyncEndpoint: getBOMValidationExplanation
          })

          this.explanation = whyExplanationResponse
          this.isExplanationExpanded = true
          recordAnalytics(BOM_WHY_EXPLANATION)
        }
        catch (e) {
          notify({
            title: `Error ${ e.status || '' }`,
            content: 'Fetch BOM validation explanation',
            type: 'negative'
          })
        }
        finally {
          this.isExplanationLoading = false
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .bom-unused-element {
    font-size: .875rem;

    ::v-deep(.q-expansion-item__content) {
      padding: .75rem;
      background-color: $mi-silver-50;
      border-top-width: $mi-expansion-item-outlined-border-width;
      border-top-style: solid;
    }
  }
</style>
