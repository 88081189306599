<template>
  <mi-tab-panel class="bom-results-elements q-px-none q-pb-xs">
    <!-- Items list with virtual scroll -->
    <mi-virtual-scroll
      v-if="items.length"
      :items="items"
      :virtual-scroll-item-size="itemSize"
      :virtual-scroll-slice-size="itemsSliceSize"
      class="full-height"
    >
      <template #default="{ item }">
        <slot :item="item"></slot>
      </template>
    </mi-virtual-scroll>

    <!-- No items found caption -->
    <div v-else class="column flex-center full-height">
      <mi-icon name="warning-circle" size="2rem"></mi-icon>
      <h6 class="q-mb-none q-mt-md"> No validation results found </h6>
    </div>
  </mi-tab-panel>
</template>

<script>
  export default {
    name: 'BomValidationResultsElements',
    props: {
      itemSize: {
        type: [Number, String],
        required: false,
        default: 48
      },
      itemsSliceSize: {
        type: [Number, String],
        required: false,
        default: 30
      },
      items: {
        type: Array,
        required: false,
        default: () => []
      }
    }
  }
</script>

<style lang="scss" scoped>
  .bom-results-elements ::v-deep(.q-virtual-scroll__content) > .mi-list-item {
    border: 2px solid $mi-anthracite-400;
  }

  // stylelint-disable selector-max-compound-selectors
  .bom-results-elements ::v-deep(.q-virtual-scroll__content) > .mi-list-item + .mi-list-item {
    border-top: 0;
  }
</style>
