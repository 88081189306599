<template>
  <div :class="wrapperClass">
    <div :class="iconClass">
      <img :src="iconSrc" alt="info-circle" width="16" height="16" />
    </div>
    <span :class="labelClass">{{ infoData?.label || '' }}</span>
  </div>
</template>

<script>
  import warningCircle from '@/assets/images/vm/warning-circle.svg'

  export default {
    name: 'BomValidationResultsElementsInfo',
    props: {
      infoData: {
        type: Object,
        required: true,
        default: () => ({})
      }
    },
    data: () => ({
      mainClassName: 'validation-results-elements-info__wrapper',
      icons: {
        warn: warningCircle
      }
    }),
    computed: {
      wrapperClass() {
        return [this.mainClassName, this.infoData?.key]
      },
      iconClass() {
        return `${ this.mainClassName }--info-icon`
      },
      labelClass() {
        return `${ this.mainClassName }--info-label`
      },
      iconSrc() {
        return this.icons[this.infoData?.key] ?? ''
      }
    }
  }
</script>

<style lang="scss" scoped>
  .validation-results-elements-info {
    &__wrapper {
      display: flex;
      height: 40px;
      padding: 10px;
      align-items: center;
      gap: 10px;
      align-self: stretch;
      margin-top: 1em;

      &--info-icon {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      &--info-label {
        font-family: $mi-typography-font-family;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 130%;
      }
    }
  }

  .warn {
    color: var(--color-semantic-warning-bold, $mi-yellow-900);
    border: 1px solid var(--color-semantic-warning-bold, $mi-yellow-900);
  }
</style>
